.background-home-banner {
  /* background-image: url('../../../assets/images/backgroundhomeold.png'), linear-gradient(to bottom, rgba(0,0,0,0.46) 0%, rgba(0,0,0,0.46) 100%); */
  background-color: #EFD918;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  height: 150px
 }

 .home-img-logo {
   width: 380px
 }
 .home-img-tecnoeste-logo {
   margin-bottom: -100px;
   margin-top: 50px;
 }
